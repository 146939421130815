<template>
  <b-sidebar
    id="edit-tag-sidebar"
    :visible="isEditTagSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="formValidation(resetblanktag).resetForm"
    @change="(val) => changed(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("tagEdit") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblanktag
    ).refFormObserver"
      >
        <!-- Form -->

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- ad -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="tagingredientname"
          >
            <b-form-group
              :label="$t('tagIngredientName')"
              label-for="tagingredientname"
            >
              <b-form-input
                id="tagingredientname"
                v-model="blankTagData.tagingredientname"
                autofocus
                :state="
                  formValidation(resetblanktag).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- info -->
          <validation-provider
            #default="validationContext"
            rules=""
            name="info"
          >
            <b-form-group :label="$t('info')" label-for="info">
              <b-form-input
                id="info"
                v-model="blankTagData.info"
                autofocus
                :state="
                  formValidation(resetblanktag).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("update") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { getUserData } from "@/auth/utils";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditTagSidebarActive",
    event: "update:is-edit-tag-sidebar-active",
  },
  props: {
    isEditTagSidebarActive: {
      type: Boolean,
      required: true,
    },
    tag: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankTagData: {},

      formValidation: formValidation,
    };
  },

  methods: {
    async changed(val) {
      this.blankTagData = { ...this.tag.item };

      this.$emit("update:is-edit-tag-sidebar-active", val);
    },
    onSubmit() {
      store
        .dispatch("plantsModule/updateIngredientTags", {
          ...this.blankTagData,
        })
        .then((isOk) => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-tag-sidebar-active", false);
          if (isOk)
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$t("successful"),
                icon: "CheckIcon",
                variant: "success",
                text: this.$t("updated", { type: this.$t("tag") }),
              },
            });
          else
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$t("unsuccessful"),
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: this.$t("updated", { type: this.$t("tag") }),
              },
            });
        });
    },
    async resetblanktag() {
      blankTagData = { ...this.tag.item };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-tag-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
