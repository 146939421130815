var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"edit-tag-sidebar","visible":_vm.isEditTagSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":function($event){_vm.formValidation(_vm.resetblanktag).resetForm},"change":function (val) { return _vm.changed(val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("tagEdit")))]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"formValidation(\n    resetblanktag\n  ).refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"rules":"required","name":"tagingredientname"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('tagIngredientName'),"label-for":"tagingredientname"}},[_c('b-form-input',{attrs:{"id":"tagingredientname","autofocus":"","state":_vm.formValidation(_vm.resetblanktag).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankTagData.tagingredientname),callback:function ($$v) {_vm.$set(_vm.blankTagData, "tagingredientname", $$v)},expression:"blankTagData.tagingredientname"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"","name":"info"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('info'),"label-for":"info"}},[_c('b-form-input',{attrs:{"id":"info","autofocus":"","state":_vm.formValidation(_vm.resetblanktag).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankTagData.info),callback:function ($$v) {_vm.$set(_vm.blankTagData, "info", $$v)},expression:"blankTagData.info"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("update"))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }